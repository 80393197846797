<template>
  <div class="jobs-table">

    <div class="text-right">
      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
        <template #button-content>
          <b-icon icon="gear-fill" variant="secondary"></b-icon>
        </template>
        <b-dropdown-item @click="downloadList(false)">Export Products (filtered)</b-dropdown-item>
        <b-dropdown-item @click="downloadList(true)">Export Products (all)</b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- Filters -->
    <div class="card p-2 pt-3 bg-light border rounded-0">
      <b-row v-show="userHasRole('superadmin')">
        <b-col xl="6" lg="6" md="12" class="my-1 align-self-end">
          <b-form-group
            label="Account"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-select
              :options="userAccounts"
              v-model="table_filters.account"
              size="sm"
              value-field="account"
              text-field="name"
              placeholder="Select account to show..."
              @change="table_filters.currentPage=1; refreshTableData()"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an account --</b-form-select-option>
              </template>
            </b-select>
            <small class="text-muted">Integration required</small> - <small class="text-muted"><b>Admin</b></small>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="6" lg="6" md="12" class="my-1">
          <b-form-group
            label="Public"
            label-for="form_public"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
            description="Public products are visible by all accounts/customers."
          >
            <b-form-select v-model="table_filters.public" size="sm" :options="yesNoList" id="form_public" @change="table_filters.currentPage=1; refreshTableData()">
              <!-- <template #first>
                <b-form-select-option :value="null" disabled>-- Please select --</b-form-select-option>
              </template> -->
            </b-form-select>
          </b-form-group>
        </b-col>
        
        <b-col xl="6" lg="6" md="12" class="my-1">
          <!-- <b-form-group
            label="Status"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
            disabled
          >
            <b-select :options="table_status_filters" v-model="table_filters.status" size="sm" value-field="id" text-field="text" @change="table_filters.currentPage=1; refreshTableData()"/>
          </b-form-group> -->

          <b-form-group
            label="Search"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-input-group class="mb-1 ml-auto">
              <b-input v-model="table_filters.search" size="sm" placeholder="Filter table..." debounce="200" v-on:change="table_filters.currentPage=1; refreshTableData(true)"/>
              <b-input-group-append>
                <b-button size="sm" v-on:click="refreshTableData(true)"><b-icon icon="arrow-clockwise"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      
    </div>

    <!-- Jobs Table -->
    <b-table
      ref="table"
      responsive
      :hover="false"
      :outlined="false"
      :borderless="false"

      primary-key="id"
      :items="tabledataProvider"
      :fields="table_fields"

      :per-page="table_filters.perPage"
      :current-page="table_filters.currentPage"

      :busy.sync="table_settings.isBusy"
      :no-local-sorting="true"
      :sort-by.sync="table_filters.sortBy"
      :sort-desc.sync="table_filters.sortDesc"
    >
      <!-- custom formatted columns -->
      <template #cell(jobid)="data">
        <b-link :to="{name:'JobDetails', params: { job_id: data.item.id }}">{{data.item.jobid}}</b-link>
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | moment("Y-MM-DD HH:mm") }}
      </template>

      <template #cell(price)="data">
        {{ data.item.price }} {{ data.item.currency }}
      </template>

      <template #cell(account)="data">
        {{ userAccountName(data.item.account) != 'Unnamed' ? userAccountName(data.item.account) : '' }}
      </template>

      <template #cell(status)="data">
        <!-- diabled removed by default, when all backends are updated -->
        <b-badge v-if="! data.item.enabled" variant="danger" class="mx-1">Disabled</b-badge>
        <!-- public is a filer, so no display here needed -->
        <!-- <b-badge v-if="data.item.public" variant="warning" class="mx-1">Public</b-badge> -->
        <b-badge v-if="data.item.logic_tags.includes('virtual')" variant="info" class="mx-1">Virtual</b-badge>
        <!-- <span v-if="data.item.tags.includes('ram')" class="d-inline-block mx-1">Frame</span> -->
        <!-- <span v-if="data.item.tags.includes('magnetlist')" class="d-inline-block mx-1">List</span> -->
      </template>

      <template #cell(stock)="data">
        <!-- infinity icon -->
        <svg v-if="data.item.logic_tags.includes('virtual')" width="16" height="16" fill="currentColor" class="bi bi-infinity" viewBox="0 0 16 16">
          <path d="M5.68 5.792 7.345 7.75 5.681 9.708a2.75 2.75 0 1 1 0-3.916ZM8 6.978 6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978Zm.656.772 1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75Z"/>
        </svg>
        <span v-else>{{ data.item.stock ? data.item.stock.reduce(function(total, item){ return total + item.available;}, 0) : 0 }}</span>
      </template>

    </b-table>

    <!-- table pagination -->
    <div class="text-right">
      <b-pagination
        class="d-inline-flex"
        v-model="table_filters.currentPage"
        :total-rows="table_settings.total_rows"
        :per-page="table_filters.perPage"
      >
      </b-pagination>
    </div>

  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductTable',
  components: {
    //OrderInclude,
  },

  props: {
    settings: {
      type: null,
      default: function () {
        return {}
      }
    },
    filters: {
      type: null,
      default: function () {
        return {}
      }
    }
  },

  data() {
    return {
      
      table_settings: {
        isBusy: false,
        total_rows: 0,
        pageOptions: [10, 15, 25, { value: 100, text: "Show a lot" }],
      },
      table_filters: { // Specify also here to make it responsive...
        currentPage: 1,
        perPage: 100,
        sortBy: 'sku',
        sortDesc: false,
        search: '', // - since we wrote our own
        status: null,
        //enabled: true,
        public: false,
        account: null,
      },

      // Table columns
      table_fields_template: [
        {
          key: 'sku',
          label: 'SKU',
          sortable: true,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          key: 'account',
          label: 'Account',
          sortable: true,
          thClass: 'd-none d-md-table-cell',
          tdClass: 'd-none d-md-table-cell'
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thClass: 'd-none d-lg-table-cell',
          tdClass: 'd-none d-lg-table-cell'
        },
        {
          access: ['superadmin'],
          key: 'price',
          label: 'Price',
          sortable: true,
          thClass: 'd-none d-lg-table-cell text-right',
          tdClass: 'd-none d-lg-table-cell text-right'
        },
        /*
        {
          key: 'weight',
          label: 'Weight (g)',
          sortable: true,
          thClass: 'd-none d-lg-table-cell text-right',
          tdClass: 'd-none d-lg-table-cell text-right'
        },
        */
        {
          key: 'status',
          label: 'Type',
          sortable: false,
          thClass: 'd-none d-lg-table-cell',
          tdClass: 'd-none d-lg-table-cell'
        },
        {
          key: 'stock',
          label: 'Available',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right'
        },
      ],

      // Filter: Status
      table_status_filters: [
        {id: null, text: 'Select to filter...' },
        {id: 'incoming', text: 'Incoming'},
        {id: 'in-production', text: 'In production'},
        {id: 'cancelled', text: 'Cancelled'},
        {id: 'failed', text: 'Failed'},
        {id: 'completed', text: 'Completed'},
        {id: 'warnings', text: 'Warnings'}
      ],
    }
  },

  async created() {
    let filters = this.getPageSettings( this.$route.name )
    this.table_settings = Object.assign({}, this.table_settings, this.settings)
    this.table_filters = Object.assign({}, this.table_filters, this.filters, filters)
    //await this.tabledataProvider();
  },

  computed: {
    ...mapGetters([
      'getPageSettings',
      'timestampAsDate'
    ]),
    ...mapGetters('job', [
      'printerapiStatusName'
    ]),
    ...mapGetters('user', [
      'userHasAnyOfRoles',
      'userAccountName',
      'userAccounts',
      'userHasRole',
    ]),

    table_fields() {
      let fields = this.table_fields_template;
      if ( ! this.userHasAnyOfRoles(['superadmin']) ) {
        fields = fields.filter( x => ! x.access || ! x.access.includes('superadmin') );
      }
      return fields;
    },

    yesNoList() { return [{ value: true, text: 'Yes' }, { value: false, text: 'No' }]; }
  },

  methods: {
    ...mapActions([
      'savePageSettings',
    ]),

    ...mapActions('product', [
      'queryProductsPromise',
    ]),

    refreshTableData() {
      this.$refs.table.refresh()
    },

    downloadList(exportAll = true) {
      let queryObject = {
        // 'status': exportAll ? null : this.table_filters.status ?? null,
        'search': exportAll ? null : this.table_filters.search ?? null,
        'public': exportAll ? null : this.table_filters.public,
        'account': exportAll ? null : this.table_filters.account ?? null,
        'perPage': 9999,
        'currentPage': 1,
      };

      return this.queryProductsPromise(queryObject)
      .then((response) => {
        let items = response?.data?.items ?? [];
        // convert to text-rows
        let content = items.map(row => {
          const available = row.stock.reduce((sum, x) => sum + x.available, 0);
          return Object.values({
            sku: row.sku,
            account: row.account,
            name: row.name,
            available: available
          }).join(';')
        }).join('\n');
        // prepend with headers
        content = 'Sku;Account;Name;Available\n' + content;

        // Create a blob
        const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        // Create a link to download it
        let pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', 'products.csv');
        pom.click();

      }).catch((error) => {
        this.$toastIt(error.message, {variant: 'danger', autoHideDelay: 5000});
      });
    },

    tabledataProvider(ctx){
      const queryObject = {
        // 'status': this.table_filters.status ?? null,
        'search': this.table_filters.search ?? null,
        'public': this.table_filters.public,
        'account': this.table_filters.account,
        //'enabled': this.table_filters.enabled,
        
        'sortBy': ctx.sortBy,
        'sortDesc': ctx.sortDesc,
        'perPage': ctx.perPage,
        'currentPage': ctx.currentPage,
      };

      // Save filters, instead of with watcher
      this.savePageSettings({id: this.$route.name, settings: this.table_filters });

      return this.queryProductsPromise(queryObject)
      .then((response) => {
        if ( response.data?.message ) { this.$toastIt(response.data.message, {variant: 'danger', autoHideDelay: 5000}); }
        this.table_settings.total_rows = response?.data?.total ?? 0;
        return response?.data?.items ?? [];
      }).catch((error) => {
        this.$toastIt(error.message, {variant: 'danger', autoHideDelay: 5000});
        return [];
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>